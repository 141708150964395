import React from 'react';
import logo from './logo.svg';
import './App.css';
import 'font-awesome/css/font-awesome.min.css';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faUsers, faLightbulb } from '@fortawesome/free-solid-svg-icons'


function App() {
  return (
    <div className="App">
      <header className="App-header">
{/*
        <img src={logo} className="App-logo" alt="logo" />
*/}
        <h1>
          Hanke Enterprises
        </h1>
        <p>
          Connecting &nbsp;<FontAwesomeIcon icon={faUsers} /> &nbsp; with &nbsp; <FontAwesomeIcon icon={faLightbulb} />
        </p>

{/*
        <FontAwesomeIcon icon={faCoffee} />
*/}
{/* 
*/}       
{/*
        <p>
          Connecting people with ideas.
        </p>
*/}       
{/*
        <p>
          Making the future present.
        </p>
*/}       
{/*
        <p>
          Imagining tomrrow today.
        </p>
*/}       
{/* 
        <p>
          Where good ideas <span style={{color: "#32CD32"}}>grow</span>. &trade;
        </p>
*/}
      </header>
    </div>
  );
}

export default App;
